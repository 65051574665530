var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"download"}},[_c('Particles',{attrs:{"id":"tsparticles","options":{
  "particles": {
    "number": {
      "value": 355,
      "density": {
        "enable": true,
        "value_area": 789.1476416322727
      }
    },
    "color": {
      "value": "#ffffff"
    },
    "shape": {
      "type": "circle",
      "stroke": {
        "width": 0,
        "color": "#000000"
      },
      "polygon": {
        "nb_sides": 5
      },
      "image": {
        "src": "img/github.svg",
        "width": 100,
        "height": 100
      }
    },
    "opacity": {
      "value": 0.48927153781200905,
      "random": false,
      "anim": {
        "enable": true,
        "speed": 0.2,
        "opacity_min": 0,
        "sync": false
      }
    },
    "size": {
      "value": 2,
      "random": true,
      "anim": {
        "enable": true,
        "speed": 2,
        "size_min": 0,
        "sync": false
      }
    },
    "line_linked": {
      "enable": false,
      "distance": 150,
      "color": "#ffffff",
      "opacity": 0.4,
      "width": 1
    },
    "move": {
      "enable": true,
      "speed": 0.2,
      "direction": "none",
      "random": true,
      "straight": false,
      "out_mode": "out",
      "bounce": false,
      "attract": {
        "enable": false,
        "rotateX": 600,
        "rotateY": 1200
      }
    }
  },
  "interactivity": {
    "detect_on": "canvas",
    "events": {
      "onhover": {
        "enable": true,
        "mode": "bubble"
      },
      "onclick": {
        "enable": true,
        "mode": "push"
      },
      "resize": true
    },
    "modes": {
      "grab": {
        "distance": 400,
        "line_linked": {
          "opacity": 1
        }
      },
      "bubble": {
        "distance": 83.91608391608392,
        "size": 1,
        "duration": 3,
        "opacity": 1,
        "speed": 3
      },
      "repulse": {
        "distance": 200,
        "duration": 0.4
      },
      "push": {
        "particles_nb": 4
      },
      "remove": {
        "particles_nb": 2
      }
    }
  },
  "retina_detect": true
}}}),_c('img',{attrs:{"src":'https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl='+_vm.dl+'&choe=UTF-8',"alt":""}}),_c('h5',[_vm._v(" Congratulation, Download your compiled application ")]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12"},[_c('div',{staticClass:"btn btn-link waves-effect",on:{"click":function($event){return _vm.openSite(_vm.dl)}}},[_vm._v(" Download ")])]),_c('div',{staticClass:"col s12"},[_c('br'),_vm._v(" For support Please start our project: "),_c('br'),_c('div',{staticClass:"gh-button",on:{"click":function($event){return _vm.openSite('https://github.com/4xmen/Anubias-Desktop/stargazers')}}},[_c('i',{staticClass:"fab fa-github"}),_vm._v(" Star ")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }